import { API } from 'aws-amplify';
import { getCommitteeListQuery, getInProgressQuery, getNextAppQuery, getSystemWideMessagesQuery } from './query';
import { dismissSystemWideMessagesMutation } from './mutation';
import { GET_COMMITTEE_LIST, GET_IN_PROGRESS, GET_NEXT_APP, CLEANUP_NEXTAPP, SUBMIT_RECOMMENDATION, SYSTEM_WIDE_MESSAGE_SET } from '../actions-index';
import { ERROR_CONSTS, SEVERITY_LEVELS, STATUS } from '../util/constants';
import { transformGetNextApp, filterInProgressByAdminAction } from './transform';

export const getCommitteeList = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_COMMITTEE_LIST,
        payload: { loading: true },
      });
    } catch (error) {
      console.log('GetCommitteeList Error:', error);
    }

    try {
      const response = await API.graphql({
        query: getCommitteeListQuery
      });

      if (response) {
        dispatch({
          type: GET_COMMITTEE_LIST,
          payload: {
            loading: false,
            committees: response?.data?.getCommitteeList?.committees,
            committeeMap: response?.data?.getCommitteeList?.committeeMap,
          },
        });
      }
    } catch (error) {
      console.log('GetCommitteeList Error:', error);
    }
  };
};

export const updateNextApp = (committeeId) => {
  let response;
  return async (dispatch) => {
    try {
      response = await API.graphql({
        query: getNextAppQuery,
        variables: {
          committeeId: committeeId,
        },
      });
      if (response) {
        dispatch({
          type: GET_NEXT_APP,
          status: STATUS?.SUCCESS,
          payload: {
            status: STATUS?.SUCCESS,
            nextApp: transformGetNextApp(committeeId, response?.data?.getNextApplication)
          },
        });
      }
      return response;
    } catch (error) {
      dispatch({
        type: GET_NEXT_APP,
        payload: {
          status: STATUS?.ERROR,
          alert: {
            MESSAGE: error?.errors[0]?.message,
            SEVERITY: SEVERITY_LEVELS?.ERROR,
          },
        },
      });
    }
  };
};

export const cleanupDashboardNextApp = () => {
  return async (dispatch) => {
    dispatch({
      type: CLEANUP_NEXTAPP,
      payload: {}
    })
    dispatch({
      type: SUBMIT_RECOMMENDATION,
      payload: {
        status: '',
        alert: ERROR_CONSTS.UI.GENERIC.EMPTY_ALERT,
      },
    });
  }
};

export const getInProgress = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_IN_PROGRESS,
        payload: { loading: true },
      });

      const response = await API.graphql({
        query: getInProgressQuery
      });

      if (response) {
        dispatch({
          type: GET_IN_PROGRESS,
          payload: {
            progress: filterInProgressByAdminAction(response?.data?.getInProgress)
          },
        });
      }
      dispatch({
        type: GET_IN_PROGRESS,
        payload: { loading: false },
      });
    } catch (error) {
      console.log('GetInProgress Error:', error);
    }
  };
};

export const getSystemWideMessages = () => {
    return async (dispatch) => {
        let type = SYSTEM_WIDE_MESSAGE_SET;
        let payload = { status: STATUS.LOADING }, response;

        dispatch({ type, payload });
        payload.status = STATUS.ERROR;
            
        try {
            const query = getSystemWideMessagesQuery;
            response = await API.graphql({ query });
            
            if (response?.data?.getSystemWideMessages.error) { throw response.data.getSystemWideMessages.error; }
            
            if (response?.data?.getSystemWideMessages?.messages?.map) {
                payload.status = STATUS.SUCCESS;
                payload.messages = response.data.getSystemWideMessages.messages.map(msg => msg.message);
                payload.messageIds = response.data.getSystemWideMessages.messages.map(msg => msg.id);
            }
        } catch (error) {
            console.log("getSystemWideMessages Error:", error);
            payload.alert = {
                MESSAGE: error?.errors?.[0]?.message ? error?.errors?.[0]?.message : "Unexpected Error",
                SEVERITY: SEVERITY_LEVELS.ERROR,
            };    
        }
        
        dispatch({ type, payload });
    };
}

export const dismissSystemWideMessages = (messageIds) => {
    return async () => {
        try {
            if (!(messageIds?.length > 0)) { return; }
            const query = dismissSystemWideMessagesMutation;
            const variables = { input: { messageIds } };
            await API.graphql({ query, variables });
        } catch (error) {
            console.error("dismissSystemWideMessages Error:", error);
        }
    };
}