import { GET_COMMITTEE_LIST, GET_IN_PROGRESS, GET_NEXT_APP, CLEANUP_NEXTAPP, SYSTEM_WIDE_MESSAGE, SYSTEM_WIDE_MESSAGE_SET, SYSTEM_WIDE_MESSAGE_CLEAR } from '../actions-index';

export const DashboardReducer = (
  state = { committees: [], committeeMap: [], loading: false, error: null },
  action = {}
) => {
  switch (action.type) {
    case GET_COMMITTEE_LIST:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export const InProgressReducer = (
  state = { progress: [], loading: false, error: null },
  action = {}
) => {
  switch (action.type) {
    case GET_IN_PROGRESS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
const initialFullState = { nextApp: [], loading: false, error: null };
export const NextAppReducer = (
  state = { nextApp: [], loading: false, error: null },
  action = {}
) => { 
  switch (action.type) {
    case CLEANUP_NEXTAPP:
      return initialFullState;
    case GET_NEXT_APP:
      return {
        ...state,
        nextApp: {
          status: action.payload.status,
          ...action.payload,
          type: GET_NEXT_APP
        }
      };
    default:
      return state;
  }
};

export function SystemWideMessageReducer(state = { type: SYSTEM_WIDE_MESSAGE, finalMessages: "" }, action) {
    function withFinalMessages(state) {
        return {
            ...state,
            finalMessages: state?.alert?.MESSAGE ?? (state?.messages?.length > 0 && state.messages) ?? ""
        };
    }
    
    switch (action?.type) {
        case SYSTEM_WIDE_MESSAGE_SET: {
            return withFinalMessages({
                type: SYSTEM_WIDE_MESSAGE,
                ...state,
                alert: action?.payload?.alert ?? {},
                messageIds: action?.payload?.messageIds ?? [],
                messages: action?.payload?.messages ?? [],
                status: action?.payload?.status ?? {},
            });
        }
        
        case SYSTEM_WIDE_MESSAGE_CLEAR: {
            return withFinalMessages({
                type: SYSTEM_WIDE_MESSAGE,
                ...state,
                alert: {},
                messages: [],
                status: action?.payload?.status ?? {},
            });
        }
    }
    
    return state;
}