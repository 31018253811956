import { Mui, MuiLab } from '@osu/react-ui';
import { useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { SYSTEM_WIDE_MESSAGE_CLEAR, getSystemWideMessages, dismissSystemWideMessages } from '../../actions-index';
import useRoleAuthentication from "../../Common/hooks/useRoleAuthentication";

function SystemWideMessageChecker({ systemWideMessagesState, getSystemWideMessages }) {
    const dispatch = useDispatch();
    const authDetails = useRoleAuthentication();
    const timer = useRef(null), initialApiCallComplete = useRef(false);
    
    useEffect(() => {
        if (initialApiCallComplete.current || !authDetails.CURRENT_EMPLID) { return; } // Skip if we're already set up OR user isn't authenticated
        
        initialApiCallComplete.current = true;
        getSystemWideMessages();
        
        const intervalMs = 60 * 10 * 1000; // 10min
        clearInterval(timer.current);
        timer.current = setInterval(getSystemWideMessages, intervalMs);
    }, [authDetails.CURRENT_EMPLID]);
    
    async function dismissSystemWideMessage(event) {
        event.stopPropagation(); // Prevent event from bubbling up and clearing other messages
        dispatch(dismissSystemWideMessages(systemWideMessagesState.messageIds)); // Save dismissals to db
        dispatch({ type: SYSTEM_WIDE_MESSAGE_CLEAR }); // Close snackbar
    }

    const oneMessagePerLine = { display: "block" };
    
    return (
        <Mui.Snackbar open={systemWideMessagesState.finalMessages} TransitionComponent={Mui.Slide} anchorOrigin={{ vertical: 'top', horizontal: 'center', }}>
            <MuiLab.Alert onClose={dismissSystemWideMessage} severity="warning" style={{}}>
                {systemWideMessagesState.finalMessages.map?.(message => (<span style={oneMessagePerLine}>{message}</span>))}
            </MuiLab.Alert>
        </Mui.Snackbar>
    );
}

const mapStateToProps = state => ({ systemWideMessagesState: state.SystemWideMessageReducer });
const mapDispatchToProps = dispatch => ({ getSystemWideMessages: () => dispatch(getSystemWideMessages()) });

export default connect(mapStateToProps, mapDispatchToProps)(SystemWideMessageChecker);